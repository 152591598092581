.HeroBanner {
  // background-image: url('../../../../public/home-banner-1.webp');
  // background-position: center;
  // background-size: cover;
  // background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  align-items: center;
  min-height: 400px;
  svg {
    font-size: 35px;
  }
  &::before {
    content: '';
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    filter: blur(30px);
    -webkit-filter: blur(30px);
  }
  .herobannerInfo {
    z-index: 11;
    position: relative;
    color: var(--dark-blue);
    font-size: 12px;
    .sub-title {
      font-weight: 500;
      color: #fff;
      font-size: 30px;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
    .title {
      font-size: 37px;
      font-family: Arial, sans-serif;
      text-transform: uppercase;
      font-weight: 800;
      margin-bottom: 0;
      background: linear-gradient(98.37deg, #f89e00 0.99%, #da2f68);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .coherentMI {
      font-weight: 500;
      font-size: 2.5rem;
      margin-bottom: 0;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: serif;
      color: #fff;
    }
    .mi-span {
      font-family: sans-serif;
      font-size: 42px;
    }
    .caption {
      font-size: 18px;
    }
    .bannerStates {
      svg,
      h2 {
        color: var(--turquoise);
        color: #fff !important;
        font-size: 25px;
        font-weight: 600 !important;
      }
      p {
        font-size: 14px;
        margin-bottom: 0;
        font-weight: 500;
      }
      svg {
        display: inline;
      }
    }
  }
  .button-style {
    padding: 17px 30px;
    font-weight: 600;
    border-radius: 100px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    background: var(--gradient);
    color: #fff;
    svg {
      font-size: 20px;
    }
  }
}
@media screen and (max-width: 768px) {
  .HeroBanner {
    .title {
      font-size: 26px !important;
    }

    .herobannerInfo {
      .sub-title {
        font-size: 20px !important;
      }
      .coherentMI {
        font-size: 26px !important;
      }
      .mi-span {
        font-size: 28px !important;
      }
      .bannerStates h2 {
        font-size: 22px !important;
        margin-bottom: 5px;
      }
      .bannerStates p {
        margin-top: 5px;
        font-size: 14px !important;
      }
    }
  }
}
