.published-reports {
  // background-image: url('../../../../public/bgServices.webp');
  // background-position: center;
  // background-size: cover;
  // background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  align-items: center;
  min-height: 500px;
  .Reports {
    .report-item {
      padding: 14px;
      background-color: #fff;
      border-radius: 10px;
      border: 1px solid #e9e9e96b;
      box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.2);
      font-size: 12px;
      h3 {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  .button-style {
    padding: 17px 30px;
    font-weight: 600;
    border-radius: 100px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    background: var(--gradient);
    color: #fff;
    svg {
      font-size: 20px;
    }
  }
  .heading-title {
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 0px;
    line-height: 40px;
    color: rgb(0, 40, 86);
    position: relative;
  }
  .heading-subtitle {
    font-size: 15px;
    margin-bottom: 0px;
    position: relative;
  }
}
